import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    authFunctions: {
        purpose: null,
        details: {
            actionName: null,
            data: null
        }
    }
};

const authFunctionalitySlice = createSlice({
    name: "authFunctions",
    initialState,
    reducers: {
        setFunction: (state, { payload }) => {
            state.authFunctions = payload
        },
        reset: (state) => {
            state.authFunctions = {
                purpose: null,
                details: {
                    actionName: null,
                    data: null
                }
            }
        }

    },
});

export const { setFunction, reset } = authFunctionalitySlice.actions;

export default authFunctionalitySlice.reducer;

