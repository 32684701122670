import { combineReducers, configureStore } from "@reduxjs/toolkit";
import projectReducer from "./features/projectSlice";
import filterReducer from "./features/filterSlice";
import favoriteReducer from "./features/favoriteSlice";
import authFunctionalityReducer from "./features/authFunctionalitySlice";
import downloadReducer from "./features/downloadSlice";
import searchReducer from "./features/searchSlice";
const rootReducer = combineReducers({
    projects: projectReducer,
    filters: filterReducer,
    favorites: favoriteReducer,
    authFunctions: authFunctionalityReducer,
    downloadState: downloadReducer,
    search: searchReducer
});

export const store = configureStore({
    reducer: rootReducer,
});






// import storage from "redux-persist/lib/storage";
// import { persistReducer, persistStore } from "redux-persist";

// const persistCreateUserFormConfig = {
//     key: " createUser",
//     storage,
// };
// createUser: persistReducer(persistCreateUserFormConfig, createUserReducer),
// export const persistor = persistStore(store);
