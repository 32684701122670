import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    projects: [],
    totalProjects: 0,
    hasMore: true
};

const projectSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        setProjects: (state, { payload }) => {
            state.projects = payload.data || [];
            state.totalProjects = payload?.totalProjects || 0;
            state.hasMore = payload?.hasMoreProject;
        },
        setNextProjects: (state, { payload }) => {
            state.projects = state.projects.concat(payload.data || []);
            state.hasMore = payload.hasMoreProject;
            if (payload.totalProjects) {
                state.totalProjects = payload.totalProjects;
            }
        },
        setHasMore: (state, { payload }) => {
            state.hasMore = payload.doesHaveMore;
        },

        setLikeProject: (state, { payload }) => {
            // Find the project by _id and toggle its isFav property
            const projectIndex = state.projects.findIndex(
                (project) => project._id === payload.projectId
            );

            if (projectIndex !== -1) {
                // Toggle the isFav value
                state.projects[projectIndex].isFav = !state.projects[projectIndex].isFav;
            }
        },

        setLikeContent: (state, { payload }) => {
            // Find the project by _id and toggle its isFav property
            console.log(payload.contentId);
            const projectIndex = state.projects.findIndex(
                (project) => project.contentId === payload.contentId
            );
            console.log(projectIndex, "PRI");
            console.log(state.projects[projectIndex], state.projects);
            if (projectIndex !== -1) {
                // Toggle the isFav value
                state.projects[projectIndex].isFav = !state.projects[projectIndex].isFav;
            }
        }
    },
});

export const { setProjects, setNextProjects, setHasMore, setLikeProject, setLikeContent } = projectSlice.actions;

export default projectSlice.reducer;

