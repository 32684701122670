import React, { lazy, Suspense, useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import routes from "./routes";
import Layout from "./layouts/Layout";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { AuthProvider } from "./contexts/AuthProvider";
import NavBar from "./layouts/main/NavBar";
import WebsitePage from "./pages/WebsitePage";
import Loader from "./components/Loader";
import AboutPage from "./pages/AboutPage";
import Login from "./layouts/Login";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios"; // Import axios for making API calls
import { baseUrl } from "./config/url";

const App = () => {
  const location = useLocation(); // Get the current route location


  // Detect route change and call the API
  useEffect(() => {
    const callViewAPI = async () => {
      console.log("Calling View");
      let routePath = location.pathname.substring(1); // Get route path without "/"
      if(routePath.length === 0){
        routePath = 'home'
      }
      try {
        await axios.get(`${baseUrl}views/${routePath}`);
      } catch (error) {
        console.error("Error calling view API:", error);
      }
    };

    callViewAPI();
  }, [location]); // Call API whenever the route changes

  return (
    <AuthProvider>
      <ToastContainer />
      <Provider store={store}>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route element={<Layout />}>
                {routes.map((route, key) => {
                  return (
                    <Route
                      key={key}
                      exact={true}
                      path={`${route.path}`}
                      element={<route.component />}
                    />
                  );
                })}
              </Route>
              <Route
                exact={true}
                path="/about"
                element={
                  <div
                    className="flex flex-col w-full"
                    style={{ minHeight: "calc(var(--vh, 1vh) * 100)" }}
                  >
                    <NavBar />
                    <div className="flex-grow border-2">
                      <AboutPage />
                    </div>
                  </div>
                }
              />
              <Route exact={true} path="/:domain" element={<WebsitePage />} />
            </Routes>
            <Login />
          </Suspense>
      </Provider>
    </AuthProvider>
  );
};

export default App;
