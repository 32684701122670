import React, { useState, useEffect, useRef } from 'react';
import { assetsUrl } from '../../config/url';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetFilters, setFilters } from '../../store/features/filterSlice';
import { getFavCount } from '../../services/projectService';
import { fetchFilterProps } from '../../services/filterService';
import { setHittingFalse, setHittingTrue } from '../../store/features/searchSlice';

const areaKeywords = [
    'shivaji park', 'laxmipuri', 'dabholkar corner', 'rajarampuri', 'kasba bawada', 
    'rukmini nagar', 'ichalkaranji', 'bawada', 'gandhinagar', 'station road', 'market yard', 
    'shahupuri', 'pratibha nagar', 'kalamba', 'nagala park', 'tarabai Park', 'unchagaon', 'phulewadi','sambhaji nagar', 'managawar peth'
  ];
  
  const typeKeywords = [
    'flats', 'flat', 'bungalow', 'bungalows', 'row house', 'row houses', 'shop', 'shops', 'office', 'offices'
  ];
  
  const configKeywords = [
    '1bhk', '1 bhk', '2bhk', '2 bhk', '3bhk', '3 bhk', '4bhk', '4 bhk', '5bhk', '5 bhk'
  ];
  
  const typeMapping = {
    'flats': 'flat',
    'flat': 'flat',
    'bungalow': 'bungalow',
    'bungalows': 'bungalow',
    'row house': 'rowhouse',
    'row houses': 'rowhouse',
    'shop': 'shop',
    'shops': 'shop',
    'office': 'office',
    'offices': 'office',
    '1bhk': '1BHK',
    '1 bhk': '1BHK',
    '2bhk': '2BHK',
    '2 bhk': '2BHK',
    '3bhk': '3BHK',
    '3 bhk': '3BHK',
    '4bhk': '4BHK',
    '4 bhk': '4BHK',
    '5bhk': '5BHK',
    '5 bhk': '5BHK'
  };


const Footer = () => {
    const dispatch = useDispatch();
    const finalFilters = {
      type: null,
      config: null,
      area: null,
      possessionStatus: null,
      minPrice: 100000,
    maxPrice: 25000000,
      nearByPlaces: null,
      title: null
    };
    const [areaList, setAreasList] = useState([]);
    const [nearByPlaceList, setNearByPlaceList] = useState([]);
    const [titleList, setTitleList] = useState([]);
    const projects = useSelector((state) => state.projects.projects);
    const filters = useSelector((state)=>state.filters);
    const [favoriteCount, setFavoriteCount] = useState(null);
    const [isUpdated, setIsUpdated] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [isSearchVisible, setSearchVisible] = useState(false);
    const [searchText, setSearchText] = useState('');
    const inputRef = useRef(null);
    const reset = () => {
      dispatch(setHittingFalse())
        dispatch(resetFilters())
        setSearchText("");
        setSearchVisible(false);
      
      }

    const getFav = async() => {
        setIsUpdated(true);
        const resp = await getFavCount();
        if(resp?.data?.count){
            setFavoriteCount(resp?.data?.count);
        }
        else{
            setFavoriteCount(null);
        }
        setTimeout(() => {
            setIsUpdated(false);
          }, 2000); 
    }

    const getFilterData = async() => {
        const resp = await fetchFilterProps();
        setTitleList(resp.data.title);
        setNearByPlaceList(resp.data.nearbyPlaces);
        setAreasList(resp.data.areas);
      }
    // Function to handle search icon click
    const handleSearchClick = () => {
      navigate('/');
        setSearchVisible(true);
    };

    // Function to handle outside click
    const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setSearchVisible(false);
        }
    };

    const handleSearchInput = (input) => {
        dispatch(resetFilters())
        // Detect keywords and set corresponding filters
        let filters = {
          type: null,
          config: null,
          area: null,
          possessionStatus: null,
          minPrice: 100000,
          maxPrice: 25000000,
          nearByPlaces: null,
          title: null
        };
      
        // Separate types and configs
        const types = [
          "flat", "bungalow", "rowhouse", "plot", "shop", "office"
        ];
      
        const configs = {
          flat: ["1BHK", "2BHK", "3BHK", "4BHK", "5BHK", "6BHK"],
          bungalow: ["2BHK", "3BHK", "4BHK", "5BHK"],
          rowhouse: ["2BHK", "3BHK", "4BHK"],
          plot: ['BELOW 1000 SQFT', 'BETWEEN 1000 TO 2000 SQFT', 'BETWEEN 2000 TO 3000 SQFT', 'ABOVE 3000 SQFT'],
          shop: ["Small", "Medium", "Large"],
          office: ["Private Cabin", "Shared Space", "Conference Room"]
        };
      
        // Normalize input text to lowercase for easier matching
        const st = input.toLowerCase();
      
        // Step 1: Check for matches with the types (detect the type if present)
        types.forEach((type) => {
          if (st.includes(type.toLowerCase())) {
            filters.type = type; // Set the detected type
          }
        });
      
        // Step 2: Check for matches with the configurations (independent of type detection)
        Object.keys(configs).forEach((type) => {
          configs[type].forEach((config) => {
            if (st.includes(config.toLowerCase())) {
              filters.config = config.toLowerCase(); // Set the corresponding config (even if no type is detected)
            }
          });
        });
      
        areaList?.forEach((area) => {
            if (area.toLowerCase().includes(st.toLowerCase())) {
              filters.area = area;
            }
            if(st.toLowerCase().includes(area.toLowerCase())){
              filters.area = area;
            }
          });
        
          // Step 4: Check for matches with the nearby places
          nearByPlaceList?.forEach((place) => {
            if (place.toLowerCase().includes(st.toLowerCase())) {
              filters.nearByPlaces = place;
            }
            if(st.toLowerCase().includes(place.toLowerCase())){
              filters.nearByPlaces = place;
            }
          });
        
          // Step 5: Check for matches with the title
          titleList?.forEach((title) => {
            if (title.toLowerCase().includes(st.toLowerCase())) {
              filters.title = title;
            }
            if(st.toLowerCase().includes(title.toLowerCase())){
              filters.title = title;
            }
          });
      
        console.log("Filters", filters);
        // Update the filters state dynamically based on detected keywords
        dispatch(setFilters(filters));
        const areObjectsEqual = (obj1, obj2) => {
          const keys1 = Object.keys(obj1);
          const keys2 = Object.keys(obj2);
        
          // Check if both objects have the same number of keys
          if (keys1.length !== keys2.length) {
            return false;
          }
        
          // Check if all keys and their values are the same in both objects
          for (let key of keys1) {
            if (obj1[key] !== obj2[key]) {
              return false;
            }
          }
        
          return true;
        };
        
        if (areObjectsEqual(finalFilters, filters)) {
          console.log("Hitting for search");
          dispatch(setHittingTrue());
        }
      };
      
      
      
      const handleSearch = () => {
        // Reset filters before every search
        dispatch(setHittingFalse())
        dispatch(
            setFilters({
                type: 'flat',
                config: null,
                area: null,
                possessionStatus: null,
                minPrice: null,
                maxPrice: 25000000,
                nearByPlaces: null,
              })
        )
        handleSearchInput(searchText);
      };

    // Attach the event listener for outside clicks
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(()=>{
        getFav()
    },[projects])

    useEffect(()=>{
        getFilterData();
      },[])

    return (
        <div className={`flex flex-row items-center justify-between w-full bg-base-100 shadow-footer-shadow py-3 marker ${isSearchVisible ? "px-6" : "px-16"}`}>
            <div onClick={handleSearchClick} className={`${isSearchVisible ? 'hidden' : 'flex'}`}>
                <img
                    src={`${assetsUrl}/assets/svgs/footer_icons/search.svg`}
                    className="w-full h-7"
                    alt="Search"
                />
            </div>
            {isSearchVisible && (
                <div ref={inputRef} className="relative flex items-center w-full">
                    <input
                        type="text"
                        value={searchText}
                        onChange={(e)=>{setSearchText(e.target.value)}}
                        className="flex-1 rounded-l-md border-l-2 border-t-2 border-b-2 border-r-0 border-base-400 px-4 py-2 focus:outline-none"
                        placeholder="Flats In Kolhapur..."
                        autoFocus
                    />
                   
                    <button
                       
                        className="text-base-600 flex items-center justify-center gap-1 rounded-r-md border-r-2 border-t-2 border-b-2 border-base-400 px-4 py-2"
                    >
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
     className='size-6 text-base-400'
     onClick={()=>{reset(); }}     
     >
  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
</svg>
                        <img  onClick={() => {
                            handleSearch();
                            // setSearchVisible(false);
                        }} src={`${assetsUrl}/assets/svgs/footer_icons/search.svg`} className="h-6" alt="search input"/>
                    </button>
                </div>
            )}
            <div className='relative'>
                <Link to="/favorite" className={`${isSearchVisible ? 'hidden' : 'flex'}`}>
                    {location.pathname.split("/").pop() === 'favorite' ? (
                        <img
                            src={`${assetsUrl}/assets/svgs/footer_icons/fav.svg`}
                            className="w-full h-7"
                            alt="Favorite"
                        />
                    ) : (
                        <>
                        
      {favoriteCount &&  <div
      className={`absolute -bottom-0 -right-1 bg-base-100 border-2 px-1 text-base-400 font-bold border-base-400 rounded-full text-xs`} // scale animation
    >{favoriteCount}</div>}
                        <img
                            src={`${assetsUrl}/assets/svgs/footer_icons/favF.svg`}
                            className="w-full h-7"
                            alt="Favorite"
                        />
                        </>
                    )}
                </Link>
               
            </div>
            <div>
                <Link to="/my-account" className={`${isSearchVisible ? 'hidden' : 'flex'}`}>
                    {location.pathname.split("/").pop() === 'my-account' ? (
                        <img
                            src={`${assetsUrl}/assets/svgs/footer_icons/accountF.svg`}
                            className="w-full h-7"
                            alt="Account"
                        />
                    ) : (
                        <img
                            src={`${assetsUrl}/assets/svgs/footer_icons/account.svg`}
                            className="w-full h-7"
                            alt="Account"
                        />
                    )}
                </Link>
            </div>
        </div>
    );
};

export default Footer;


// import React, { useState, useEffect, useRef } from 'react';
// import { assetsUrl } from '../../config/url';
// import { Link, useLocation, useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { setFilters } from '../../store/features/filterSlice';
// import { getFavCount } from '../../services/projectService';

// const areaKeywords = [
//     'shivaji park', 'laxmipuri', 'dabholkar corner', 'rajarampuri', 'kasba bawada', 
//     'rukmini nagar', 'ichalkaranji', 'bawada', 'gandhinagar', 'station road', 'market yard', 
//     'shahupuri', 'pratibha nagar', 'kalamba', 'nagala park', 'tarabai Park', 'unchagaon', 'phulewadi','sambhaji nagar', 'managawar peth'
//   ];
  
//   const typeKeywords = [
//     'flats', 'flat', 'bungalow', 'bungalows', 'row house', 'row houses', 'shop', 'shops', 'office', 'offices'
//   ];
  
//   const configKeywords = [
//     '1bhk', '1 bhk', '2bhk', '2 bhk', '3bhk', '3 bhk', '4bhk', '4 bhk', '5bhk', '5 bhk'
//   ];
  
//   const typeMapping = {
//     'flats': 'flat',
//     'flat': 'flat',
//     'bungalow': 'bungalow',
//     'bungalows': 'bungalow',
//     'row house': 'rowhouse',
//     'row houses': 'rowhouse',
//     'shop': 'shop',
//     'shops': 'shop',
//     'office': 'office',
//     'offices': 'office',
//     '1bhk': '1BHK',
//     '1 bhk': '1BHK',
//     '2bhk': '2BHK',
//     '2 bhk': '2BHK',
//     '3bhk': '3BHK',
//     '3 bhk': '3BHK',
//     '4bhk': '4BHK',
//     '4 bhk': '4BHK',
//     '5bhk': '5BHK',
//     '5 bhk': '5BHK'
//   };


// const Footer = () => {
//     const dispatch = useDispatch();
//     const projects = useSelector((state) => state.projects.projects);
//     const filters = useSelector((state)=>state.filters);
//     const [favoriteCount, setFavoriteCount] = useState(null);
//     const [isUpdated, setIsUpdated] = useState(false);
//     const location = useLocation();
//     const navigate = useNavigate();
//     const [isSearchVisible, setSearchVisible] = useState(false);
//     const [searchText, setSearchText] = useState('');
//     const inputRef = useRef(null);


//     const getFav = async() => {
//         setIsUpdated(true);
//         const resp = await getFavCount();
//         if(resp?.data?.count){
//             setFavoriteCount(resp?.data?.count);
//         }
//         else{
//             setFavoriteCount(null);
//         }
//         setTimeout(() => {
//             setIsUpdated(false);
//           }, 2000); 
//     }
//     // Function to handle search icon click
//     const handleSearchClick = () => {
//       navigate('/');
//         setSearchVisible(true);
//     };

//     // Function to handle outside click
//     const handleClickOutside = (event) => {
//         if (inputRef.current && !inputRef.current.contains(event.target)) {
//             setSearchVisible(false);
//         }
//     };

//     const handleSearchInput = (input) => {
//         const lowerInput = input.toLowerCase();
//         const updatedFilters = { ...filters };
    
//         // Area keyword matching - Ensure "Tarabai Park" and other areas work
//         const foundArea = areaKeywords.find(area => lowerInput.includes(area.toLowerCase()));
//         if (foundArea) {
//           updatedFilters.area = foundArea.charAt(0).toUpperCase() + foundArea.slice(1);
//         }
    
//         // Property type keyword matching
//         const foundType = typeKeywords.find(type => lowerInput.includes(type));
//         if (foundType && typeMapping[foundType]) {
//           updatedFilters.type = typeMapping[foundType];
//         }
    
//         // Property configuration keyword matching
//         const foundConfig = configKeywords.find(config => lowerInput.includes(config));
//         if (foundConfig) {
//           updatedFilters.config = typeMapping[foundConfig];
//         }
    
//         // Check for price information
//        // Match for min price, below, minimum, and the start of the string
//     const minPriceMatch = input.match(/(?:min\s|minimum\s|above\s|^)([0-9]+)/i);
    
//     // Match for max price, above, maximum, and the end of the string
//     const maxPriceMatch = input.match(/(?:max\s|maximum\s|below\s|$)([0-9]+)/i);
    
        
//         if (minPriceMatch) {
//           updatedFilters.minPrice = parseInt(minPriceMatch[1], 10);
//         } else {
//           updatedFilters.minPrice = null;
//         }
    
//         if (maxPriceMatch) {
//           updatedFilters.maxPrice = parseInt(maxPriceMatch[1], 10);
//         } else {
//           updatedFilters.maxPrice = 25000000;
//         }
    
//         // Apply the updated filters
//         dispatch(setFilters(updatedFilters));
//       };
      
//       const handleSearch = () => {
//         // Reset filters before every search
//         dispatch(
//             setFilters({
//                 type: 'flat',
//                 config: null,
//                 area: null,
//                 possessionStatus: null,
//                 minPrice: null,
//                 maxPrice: 25000000,
//                 nearByPlaces: null,
//               })
//         )
//         handleSearchInput(searchText);
//       };

//     // Attach the event listener for outside clicks
//     useEffect(() => {
//         document.addEventListener('mousedown', handleClickOutside);
//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, []);

//     useEffect(()=>{
//         getFav()
//     },[projects])

//     return (
//         <div className={`flex flex-row items-center justify-between w-full bg-base-100 shadow-footer-shadow py-3 marker ${isSearchVisible ? "px-6" : "px-16"}`}>
//             <div onClick={handleSearchClick} className={`${isSearchVisible ? 'hidden' : 'flex'}`}>
//                 <img
//                     src={`${assetsUrl}/assets/svgs/footer_icons/search.svg`}
//                     className="w-full h-7"
//                     alt="Search"
//                 />
//             </div>
//             {isSearchVisible && (
//                 <div ref={inputRef} className="relative flex items-center w-full">
//                     <input
//                         type="text"
//                         value={searchText}
//                         onChange={(e)=>{setSearchText(e.target.value)}}
//                         className="flex-1 rounded-l-md border-l-2 border-t-2 border-b-2 border-r-0 border-base-400 px-4 py-2 focus:outline-none"
//                         placeholder="Flats In Kolhapur..."
//                         autoFocus
//                     />
//                     <button
//                         onClick={() => {
//                             handleSearch();
//                             setSearchVisible(false);
//                         }}
//                         className="text-base-600 flex items-center justify-center gap-1 rounded-r-md border-r-2 border-t-2 border-b-2 border-base-400 px-4 py-2"
//                     >
//                         <img src={`${assetsUrl}/assets/svgs/footer_icons/search.svg`} className="h-6" alt="search input"/>
//                     </button>
//                 </div>
//             )}
//             <div className='relative'>
//                 <Link to="/favorite" className={`${isSearchVisible ? 'hidden' : 'flex'}`}>
//                     {location.pathname.split("/").pop() === 'favorite' ? (
//                         <img
//                             src={`${assetsUrl}/assets/svgs/footer_icons/fav.svg`}
//                             className="w-full h-7"
//                             alt="Favorite"
//                         />
//                     ) : (
//                         <>
                        
//       {favoriteCount &&  <div
//       className={`absolute -bottom-0 -right-1 bg-base-100 border-2 px-1 text-base-400 font-bold border-base-400 rounded-full text-xs`} // scale animation
//     >{favoriteCount}</div>}
//                         <img
//                             src={`${assetsUrl}/assets/svgs/footer_icons/favF.svg`}
//                             className="w-full h-7"
//                             alt="Favorite"
//                         />
//                         </>
//                     )}
//                 </Link>
               
//             </div>
//             <div>
//                 <Link to="/my-account" className={`${isSearchVisible ? 'hidden' : 'flex'}`}>
//                     {location.pathname.split("/").pop() === 'my-account' ? (
//                         <img
//                             src={`${assetsUrl}/assets/svgs/footer_icons/accountF.svg`}
//                             className="w-full h-7"
//                             alt="Account"
//                         />
//                     ) : (
//                         <img
//                             src={`${assetsUrl}/assets/svgs/footer_icons/account.svg`}
//                             className="w-full h-7"
//                             alt="Account"
//                         />
//                     )}
//                 </Link>
//             </div>
//         </div>
//     );
// };

// export default Footer;
