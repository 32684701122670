// import React, { useEffect, useRef, useState } from 'react';
// import NavBar from './main/NavBar';
// import Footer from './main/Footer';
// import { Outlet, useLocation } from 'react-router-dom'; // Assuming Loader component is in the same folder
// import Loader from '../components/Loader';

// const Layout = () => {
//   const [outletHeight, setOutletHeight] = useState('auto');
//   const [isLoading, setIsLoading] = useState(true); // State for showing loader
//   const location = useLocation();
//   const footerRef = useRef(null);
//   const navbarRef = useRef(null);

//   useEffect(() => {
//     const handleResize = () => {
//       calculateOutletHeight();
//       document.body.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
//     };

//     const calculateOutletHeight = () => {
//       const navbarHeight = navbarRef.current ? navbarRef.current.offsetHeight : 0;
//       const footerHeight = footerRef.current ? footerRef.current.offsetHeight : 0;
//       const remainingHeight = window.innerHeight - navbarHeight - footerHeight;

//       setOutletHeight(remainingHeight > 0 ? `${remainingHeight - 80}px` : '0px');
//     };

//     window.addEventListener('resize', handleResize);
//     handleResize(); // Set initial value

//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   // Show loader for 2 seconds before displaying content
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setIsLoading(false); // Hide loader after 2 seconds
//     }, 2000);

//     return () => clearTimeout(timer); // Cleanup timer on unmount
//   }, []);

//   return (
//     <div className='flex flex-col items-center max-w-[2000px] mx-auto' style={{ minHeight: 'calc(var(--vh, 1vh) * 100)' }}>
//       {isLoading ? (
//         <Loader />// Display Loader component
//       ) : (
//         <>
//           {location.pathname.split("/")[location.pathname.split("/").length - 1] !== 'my-account' &&
//             <div className='w-full block sm:hidden'>
//               <NavBar />
//             </div>
//           }
//           <div className='hidden sm:block w-full'>
//             <NavBar />
//           </div>
//           <div
//             className='flex-grow w-full'
//             style={{ height: outletHeight }}
//           >
//             <Outlet />
//           </div>
//           <div className='w-full flex sm:hidden' ref={footerRef}>
//             <Footer />
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default Layout;


import React, { useEffect, useRef, useState } from 'react';
import NavBar from './main/NavBar';
import Footer from './main/Footer';
import { Outlet, useLocation } from 'react-router-dom';
import Loader from '../components/Loader';

const Layout = () => {
  const [outletHeight, setOutletHeight] = useState('auto');
  const [isSplashVisible, setIsSplashVisible] = useState(true); // State for splash screen visibility
  const location = useLocation();
  const footerRef = useRef(null);
  const navbarRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      calculateOutletHeight();
      document.body.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    const calculateOutletHeight = () => {
      const navbarHeight = navbarRef.current ? navbarRef.current.offsetHeight : 0;
      const footerHeight = footerRef.current ? footerRef.current.offsetHeight : 0;
      const remainingHeight = window.innerHeight - navbarHeight - footerHeight;

      setOutletHeight(remainingHeight > 0 ? `${remainingHeight - 80}px` : '0px');
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial value

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Show splash screen for 2 seconds
  useEffect(() => {
    const splashTimer = setTimeout(() => {
      setIsSplashVisible(false); // Hide splash screen after 2 seconds
    }, 2500);

    return () => clearTimeout(splashTimer); // Cleanup timer on unmount
  }, []);

  return (
    <div className='flex flex-col items-center max-w-[2000px] mx-auto' style={{ minHeight: 'calc(var(--vh, 1vh) * 100)' }}>
      {/* Splash screen overlay */}
      {isSplashVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-white z-50">
          <Loader /> {/* Full-screen loader overlay */}
        </div>
      )}
      {/* Actual content (mounted immediately) */}
      {location.pathname.split("/")[location.pathname.split("/").length - 1] !== 'my-account' &&
        <div className='w-full block sm:hidden' ref={navbarRef}>
          <NavBar />
        </div>
      }
      <div className='hidden sm:block w-full' ref={navbarRef}>
        <NavBar />
      </div>
      <div
        className='flex-grow w-full'
        style={{ height: outletHeight }}
      >
        <Outlet />
      </div>
      <div className='w-full flex sm:hidden' ref={footerRef}>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;

