import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   downloadState: false,
};

const downloadSlice = createSlice({
    name: "downloadState",
    initialState,
    reducers: {
        setDownloadState: (state, {payload}) => {
            state.downloadState = payload.downloadState;
        },
        setReset: (state) => {
            state.downloadState = false;
        }
               
    },
});

export const { setDownloadState, setReset } = downloadSlice.actions;

export default downloadSlice.reducer;

