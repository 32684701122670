import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    hitting: false,
};

const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setHittingTrue: (state, { payload }) => {
            state.hitting = true;
        },
        setHittingFalse: (state, { payload }) => {
            state.hitting = false;
        },
    },
});

export const { setHittingFalse, setHittingTrue } = searchSlice.actions;

export default searchSlice.reducer;
